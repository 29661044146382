/* autoprefixer grid: autoplace */
body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

#start {
  height: 100vh;
}

@font-face {
  font-family: "Muli";
  src: url("./fonts/Muli-Regular.eot");
  src: url("./fonts/Muli-Regular.eot?#iefix") format("embedded-opentype"), url("./fonts/Muli-Regular.woff2") format("woff2"),
    url("./fonts/Muli-Regular.woff") format("woff"), url("./fonts/Muli-Regular.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Muli";
  src: url("./fonts/Muli-SemiBold.eot");
  src: url("./fonts/Muli-SemiBold.eot?#iefix") format("embedded-opentype"), url("./fonts/Muli-SemiBold.woff2") format("woff2"),
    url("./fonts/Muli-SemiBold.woff") format("woff"), url("./fonts/Muli-SemiBold.ttf") format("truetype");
  font-weight: 600;
  font-style: normal;
}

/* Muli bold */

@font-face {
  font-family: "Muli";
  src: url("./fonts/Muli-Bold.eot");
  src: url("./fonts/Muli-Bold.eot?#iefix") format("embedded-opentype"), url("./fonts/Muli-Bold.woff2") format("woff2"),
    url("./fonts/Muli-Bold.woff") format("woff"), url("./fonts/Muli-Bold.ttf") format("truetype");
  font-weight: 700;
  font-style: normal;
}
